import React, { FC, useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { colors, shadows } from 'src/config/colors';
import { Form, Formik } from 'formik';
import { FormItem } from 'src/common/components/FormItem';
import { TextControl } from 'src/common/components/TextControl';
import { object, string } from 'yup';
import { Button } from 'src/common/components/Button';
import { ForgotPasswordRoute, RegisterRoute } from 'src/public/publicRoutes';
import { media } from 'src/config/breakpoints';
import { PasswordControl } from 'src/common/components/PasswordControl';
import { AuthCourseCard } from 'src/public/components/auth/AuthCourseCard';
import { AuthLayout } from 'src/public/components/auth/AuthLayout';
import { typography } from 'src/config/typography';
import { rem } from 'polished';
import { AuthLoginPanelRightList } from 'src/public/components/auth/AuthLoginPanelRightList';
import {
  LoginFormModel,
  mapToAuthReqDTO,
  mapToForgotPasswordPageState,
} from 'src/common/models/auth';
import { pipe } from 'fp-ts/function';
import { useLoginMutation } from 'src/common/hooks/useLoginMutation';
import { useLoggedOutGuard } from 'src/common/guards/useLoggedOutGuard';
import { layers } from 'src/config/layers';
import { AuthButton } from 'src/public/components/auth/AuthButton';
import { pageContextActions } from 'src/state/pageContext/pageContextActions';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { PageContextType } from 'src/state/pageContext/pageContextReducer';
import { authCourseExcerptSelector } from 'src/state/pageContext/pageContextSelectors';
import { VendorGetStaticProps } from 'vendor/utils/VendorGetStaticProps';

const Box = styled.div<{ course: boolean }>`
  background: ${colors.white};
  box-shadow: ${shadows.drop1};
  border-radius: 0.5rem;
  flex: 1 1 auto;
  padding: ${({ course }) => (course ? `0.9375rem` : `2.8125rem`)} 0.75rem 2.8125rem;

  ${media.w.min.px576(css`
    margin-right: -0.75rem;
  `)};

  ${media.w.min.px768(css`
    margin-right: -0.75rem;
    padding: 1.875rem 3.25rem 3.75rem 2.875rem;
  `)};

  ${media.w.min.px992(css`
    margin-right: -0.375rem;
    padding: 3.0625rem;
  `)};

  ${media.w.min.px1200(css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3.0625rem 6.625rem;
  `)};
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 72.1875rem;

  ${media.w.min.px768(css`
    display: flex;
  `)};
`;

const FormButton = styled.div`
  margin-top: 0.625rem;

  ${media.w.between.px768.px992(css`
    margin-top: 1.25rem;
  `)};
`;

const LogInLinkContainer = styled.div`
  margin-top: 1rem;

  ${media.w.min.px576(css`
    text-align: center;
  `)};

  ${media.w.min.px992(css`
    text-align: left;
  `)};
`;

const PanelLeft = styled.div`
  position: relative;
  z-index: ${layers.contentTop};

  ${media.w.min.px768(css`
    display: flex;
    width: 57%;
  `)};

  ${media.w.min.px992(css`
    width: 50%;
  `)};
`;

const PanelRight = styled.div`
  background: ${colors.grey05};
  border-radius: 0.5rem;
  padding: 2.1875rem 0.75rem;
  position: relative;
  z-index: ${layers.contentBottom};

  ${media.w.min.px768(css`
    padding: 1.875rem 1.5rem 2.1875rem 3.125rem;
    width: 43%;
  `)};

  ${media.w.min.px992(css`
    padding: 3.0625rem 1.5rem 1.5rem 3.125rem;
    width: 50%;
  `)};

  ${media.w.min.px1200(css`
    padding: 7.875rem 1.5rem 10.75rem 5.75rem;
  `)};
`;

const StyledAuthCourseCard = styled(AuthCourseCard)`
  margin-top: 0.8125rem;

  ${media.w.min.px576(css`
    margin-left: auto;
    margin-right: auto;
  `)};

  ${media.w.min.px768(css`
    margin-top: 1.25rem;
  `)};

  ${media.w.min.px992(css`
    max-width: none;
  `)};
`;

const StyledButton = styled(Button)`
  display: block;
  margin: 0 auto;
  width: 100%;

  ${media.w.min.px576(css`
    width: 12.5rem;
  `)};

  ${media.w.min.px992(css`
    margin: 0;
  `)};
`;

const StyledForm = styled(Form)`
  margin: 1.75rem auto 0;
  max-width: 25.625rem;
  width: 100%;

  ${media.w.min.px992(css`
    max-width: none;
  `)};
`;

const StyledLink = styled(Button)`
  font-size: 0.625rem;
  line-height: 0.875rem;
`;

export const Title = styled.div<{ course: boolean }>`
  font-family: ${typography.fontFamilyHeaders};
  font-size: ${({ course }) => (course ? rem(20) : rem(24))};
  line-height: ${({ course }) => (course ? rem(25) : rem(30))};
  text-align: center;

  ${media.w.min.px576(css`
    font-size: 1.5rem;
    line-height: 1.875rem;
  `)};

  ${({ course }) =>
    media.w.min.px768(css`
      margin-bottom: ${course ? rem(20) : rem(60)};
      text-align: ${course ? 'left' : 'center'};
    `)}

  ${media.w.min.px992(css`
    font-size: 2.3125rem;
    line-height: 2.875rem;
    margin-bottom: 1.25rem;
    text-align: left;
  `)};
`;

export const LoginLeftPanelTitle = styled(Title)`
  margin-bottom: 0;

  ${({ course }) =>
    media.w.between.px768.px992(css`
      margin-bottom: ${course ? 0 : rem(60)};
    `)}
`;

const initialValues: LoginFormModel = {
  login: '',
  password: '',
};

const validationSchema = object().shape({
  login: string().required('Pole obowiązkowe').email('Podaj poprawny adres email'),
  password: string().required('Pole obowiązkowe'),
});

const LoginPage: FC = () => {
  const dispatch = useDispatch();
  const mutation = useLoginMutation();
  const router = useRouter();
  const shouldRender = useLoggedOutGuard();

  const authCourseExcerpt = useSelector(authCourseExcerptSelector);
  const cleanAuthCourseExcerptOnExit = useRef(true);

  const handleRegisterRedirect = useCallback(() => {
    cleanAuthCourseExcerptOnExit.current = false;
    router.push(RegisterRoute);
  }, []);

  const redirectToForgotPassword = useCallback((values: LoginFormModel) => {
    dispatch(
      pageContextActions.setRequestResetPasswordFormCache(mapToForgotPasswordPageState(values)),
    );
    router.push(ForgotPasswordRoute);
  }, []);

  useEffect(() => {
    return () => {
      cleanAuthCourseExcerptOnExit.current &&
        dispatch(pageContextActions.cleanPageContext(PageContextType.AuthCourseExcerpt));
    };
  }, []);

  if (!shouldRender) {
    return null;
  }

  return (
    <AuthLayout title="Logowanie">
      <Container>
        <PanelLeft>
          <Box course={!!authCourseExcerpt}>
            <LoginLeftPanelTitle course={!!authCourseExcerpt}>
              {!authCourseExcerpt && <>Zaloguj się</>}
              {authCourseExcerpt && <>Zaloguj się i&nbsp;zapisz na&nbsp;szkolenie</>}
            </LoginLeftPanelTitle>

            {authCourseExcerpt && <StyledAuthCourseCard course={authCourseExcerpt} />}

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                pipe(values, mapToAuthReqDTO, mutation.mutate);
                setSubmitting(false);
              }}
            >
              {({ values }) => (
                <StyledForm noValidate>
                  <FormItem name="login">
                    <TextControl type="email" name="login" label="Adres e-mail" />
                  </FormItem>

                  <FormItem name="password">
                    <PasswordControl name="password" label="Hasło" editable />
                  </FormItem>

                  <FormButton>
                    <AuthButton type="submit" loading={mutation.isLoading}>
                      Zaloguj się
                    </AuthButton>
                  </FormButton>

                  <LogInLinkContainer>
                    <StyledLink
                      decoration="underline"
                      kind="link"
                      onClick={() => redirectToForgotPassword(values)}
                    >
                      Nie pamiętam hasła
                    </StyledLink>
                  </LogInLinkContainer>
                </StyledForm>
              )}
            </Formik>
          </Box>
        </PanelLeft>

        <PanelRight>
          <Title course>Nie masz konta?</Title>

          <AuthLoginPanelRightList />

          <StyledButton kind="secondary" onClick={handleRegisterRedirect}>
            Załóż konto
          </StyledButton>
        </PanelRight>
      </Container>
    </AuthLayout>
  );
};

export const getStaticProps = VendorGetStaticProps(() => ({
  props: {},
  revalidate: false,
}));

export default LoginPage;

import { TextControl, TextControlProps } from 'src/common/components/TextControl';
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import { media } from 'src/config/breakpoints';

const Container = styled.div`
  position: relative;
`;

const StyledButton = styled(Button)`
  color: ${colors.blue07};
  font-weight: 300;
  line-height: 0.875rem;

  ${media.w.min.px768(css`
    font-size: 0.625rem;
  `)};
`;

const Toggle = styled.div`
  position: absolute;
  right: 1rem;
  text-align: center;
  top: 1.0625rem;
  width: 1.875rem;

  ${media.w.min.px768(css`
    top: 0.875rem;
  `)};
`;

export const PasswordControl: FC<TextControlProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Container>
      <TextControl {...props} type={showPassword ? 'text' : 'password'} />

      {props.editable && (
        <Toggle>
          <StyledButton
            decoration="underline"
            kind="link"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? 'Ukryj' : 'Pokaż'}
          </StyledButton>
        </Toggle>
      )}
    </Container>
  );
};

PasswordControl.displayName = 'TextControl';

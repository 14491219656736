import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { media } from 'src/config/breakpoints';
import { typography } from 'src/config/typography';
import { StyleProps } from 'src/lib/styleProps';
import { format } from 'src/lib/date';
import { AuthCourseExcerptPayload } from 'src/state/pageContext/pageContextPayloads';
import Image from 'next/image';

const Container = styled.div`
  align-items: center;
  background: ${colors.grey05};
  border-radius: 0.5rem;
  display: grid;
  grid-template-areas:
    'title'
    'date';
  padding: 0.8125rem 2.8125rem 0.9375rem;
  width: 100%;

  ${media.w.min.px576(css`
    grid-template-areas: 'title date';
    max-width: 25.625rem;
    padding: 0.5rem 1.5rem 0.5rem 1.25rem;
  `)};

  ${media.w.min.px992(css`
    align-items: start;
    grid-template-areas:
      'image title'
      'image date';
    max-width: 23.125rem;
    padding: 0.8125rem 0.6875rem 0.8125rem 1.25rem;
  `)};
`;

const CourseDate = styled.div`
  font-style: italic;
  font-weight: 300;
  font-size: 0.75rem;
  grid-area: date;
  line-height: 1.5625rem;
  white-space: nowrap;

  ${media.w.max.px576(css`
    text-align: center;
  `)};

  b {
    color: ${colors.pfp3};
    font-family: ${typography.fontFamilyHeaders};
    font-size: 1.25rem;
    margin-right: 0.1875rem;
  }

  span {
    background: ${colors.darkGrey};
    border-radius: 0.25rem;
    display: inline-block;
    margin-right: 0.3125rem;
    padding: 0 0.4375rem 0 0.375rem;
    text-transform: uppercase;
  }
`;

const ImageWrapper = styled.div`
  grid-area: image;
  margin-right: 1.1875rem;
  width: 5rem;
  max-with: 100%;
  position: relative;

  ${media.w.max.px992(css`
    display: none;
  `)};
`;

const Title = styled.div`
  font-family: ${typography.fontFamilyHeaders};
  font-size: 0.875rem;
  grid-area: title;
  line-height: 1.375rem;
  margin-bottom: 0.4375rem;
  text-align: center;
  overflow-wrap: anywhere;

  ${media.w.min.px576(css`
    margin-right: 1.25rem;
    text-align: left;
  `)};

  ${media.w.min.px992(css`
    margin: 0.25rem 0 0.5rem;
  `)};
`;

export interface AuthCourseCardProps extends StyleProps {
  course: AuthCourseExcerptPayload;
}

export const AuthCourseCard = memo<AuthCourseCardProps>(({ className, course, style }) => (
  <Container className={className} style={style}>
    <ImageWrapper>
      <Image src={course.image.url} width={course.image.width} height={course.image.height} />
    </ImageWrapper>

    <Title dangerouslySetInnerHTML={{ __html: course.title }} />

    {course.date && (
      <CourseDate>
        <span>
          <b>{format(course.date, 'dd')}</b> {format(course.date, 'MMM')}
        </span>
        {format(course.date, 'HH:mm')}
      </CourseDate>
    )}
  </Container>
));
AuthCourseCard.displayName = 'AuthCourseCard';

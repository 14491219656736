import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { typography } from 'src/config/typography';
import FileIcon from 'src/assets/icons/FileIcon';
import ChatIcon from 'src/assets/icons/ChatIcon';

const List = styled.div`
  margin: 0 auto;
  max-width: 14.5rem;
  padding-bottom: 0.625rem;

  ${media.w.min.px768(css`
    margin: 0;
    max-width: none;
    padding-bottom: 1.0625rem;
  `)};

  ${media.w.min.px992(css`
    display: flex;
    margin: 1.25rem -1rem 2.8125rem;
    padding-bottom: 0;
  `)};
`;

const ListIcon = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  margin-right: 1.25rem;
  width: 2.125rem;

  ${media.w.min.px768(css`
    justify-content: flex-start;
    height: 2.875rem;
    margin-right: 0;
    width: 100%;
  `)};
`;

const ListItem = styled.div`
  display: flex;
  margin: 1.75rem 0;

  ${media.w.min.px768(css`
    display: block;
    flex: 0 1 9.6875rem;
    margin: 0 0 1.5625rem;
    width: 9.6875rem;
  `)};

  ${media.w.min.px992(css`
    margin-bottom: 0;
    padding: 0 1rem;
  `)};

  &:last-child {
    ${media.w.min.px768(css`
      margin-bottom: 0;
    `)};
  }
`;

const ListText = styled.div`
  font-family: ${typography.fontFamilyHeaders};
  line-height: 1.25rem;
`;

const ListTitle = styled.div`
  font-family: ${typography.fontFamilyHeaders};

  span {
    font-size: 2.3125rem;
  }
`;

export const AuthLoginPanelRightList = memo(() => (
  <List>
    <ListItem>
      <ListIcon>
        <ListTitle>
          <span>0</span>zł
        </ListTitle>
      </ListIcon>

      <ListText>Bierz udział w&nbsp;dziesiątkach bezpłatnych szkoleń</ListText>
    </ListItem>

    <ListItem>
      <ListIcon>
        <FileIcon size={27} />
      </ListIcon>

      <ListText>Pobieraj materiały pomocnicze tj. wzory i&nbsp;dokumenty</ListText>
    </ListItem>

    <ListItem>
      <ListIcon>
        <ChatIcon size={25} />
      </ListIcon>

      <ListText>Konsultuj swoje wyzwania biznesowe z&nbsp;ekspertami</ListText>
    </ListItem>
  </List>
));
AuthLoginPanelRightList.displayName = 'AuthLoginPanelRightList';

import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import { PageContextState, PageContextType } from 'src/state/pageContext/pageContextReducer';

export const pageStateSelector = (state: RootState) => state.pageContext;

export const authCourseExcerptSelector = createSelector(
  pageStateSelector,
  (state: PageContextState) => state[PageContextType.AuthCourseExcerpt],
);

export const registeredUserCacheSelector = createSelector(
  pageStateSelector,
  (state: PageContextState) => state[PageContextType.RegisteredUserCache],
);

export const requestResetPasswordFormCacheSelector = createSelector(
  pageStateSelector,
  (state: PageContextState) => state[PageContextType.RequestResetPasswordFormCache],
);

export const redirectUrlSelector = createSelector(
  pageStateSelector,
  (state: PageContextState) => state[PageContextType.RedirectUrl],
);

import { useIsLoggedIn } from 'src/common/hooks/useIsLoggedIn';
import { HomepageRoute } from 'src/public/publicRoutes';
import { useSelector } from 'react-redux';
import { redirectUrlSelector } from 'src/state/auth/authSelectors';
import { fold } from 'fp-ts/lib/Option';
import { RedirectUrl } from 'src/state/auth/authActions';
import { useClientReady } from 'src/lib/SSR/ClientOnly/ClientOnly';
import { translatedPathnames } from 'src/config/routes';
import { useRouter } from 'next/router';
import { buildAsUrl } from 'src/lib/router.utils';

export const useLoggedOutGuard = (): boolean => {
  const client = useClientReady();
  const { isLoading, isLoggedIn } = useIsLoggedIn();
  const redirectUrlSome = useSelector(redirectUrlSelector);
  const router = useRouter();

  if (!client) {
    return true;
  }

  if (!isLoading && isLoggedIn) {
    const redirectUrl = fold<RedirectUrl, RedirectUrl>(
      () => ({ pathname: HomepageRoute, publicRoute: true }),
      (redirectUrl) => redirectUrl,
    )(redirectUrlSome);

    router.replace(
      {
        pathname: redirectUrl.pathname,
        query: redirectUrl.query,
      },
      buildAsUrl({
        pathname: translatedPathnames[redirectUrl.pathname] ?? redirectUrl.pathname,
        query: redirectUrl.query,
      }),
    );

    return false;
  }

  return !isLoading;
};

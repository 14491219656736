import { useMutation, useQueryClient } from 'react-query';
import { writeToStorage } from 'src/common/services/queryStorage';
import { QueryKey } from 'src/config/queryKey';
import { Auth } from 'src/common/models/auth';
import { login } from 'src/common/services/auth';

export const useLoginMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(login, {
    onSuccess: (authData: Auth) => {
      queryClient.setQueryData(QueryKey.Auth, authData);
      writeToStorage(QueryKey.Auth, authData);
    },
  });
};

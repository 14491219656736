import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const FileIcon = (props: IconProps) => (
  <IconBox {...props}>
    <g clipPath="url(#FileIconClip)" clipRule="evenodd" fillRule="evenodd">
      <path d="M8.35076 2.39764C9.88594.86245 11.9681 0 14.1392 0h23.8139c.5921 0 1.16.23522 1.5787.6539l17.8604 17.8605c.4187.4187.6539.9865.6539 1.5786v35.7209c0 2.1711-.8624 4.2533-2.3976 5.7885C54.1133 63.1375 52.0312 64 49.8601 64H14.1392c-2.1711 0-4.25326-.8625-5.78844-2.3976-1.53518-1.5352-2.39764-3.6174-2.39764-5.7885V8.18605c0-2.17108.86246-4.25323 2.39764-5.78841zm5.78844 2.06748c-.9869 0-1.9333.39202-2.6311 1.08983a3.72103 3.72103 0 00-1.0899 2.6311V55.8139c0 .9869.3921 1.9333 1.0899 2.6311a3.72052 3.72052 0 002.6311 1.0899h35.7209c.9868 0 1.9333-.392 2.6311-1.0899a3.72085 3.72085 0 001.0898-2.6311V21.0178L37.0284 4.46512H14.1392z" />
      <path d="M37.9531 0c1.233 0 2.2321.99955 2.2321 2.23256V17.8605h15.6279c1.2331 0 2.233.9995 2.233 2.2325 0 1.233-.9999 2.2326-2.233 2.2326H37.9527c-1.233 0-2.2326-.9996-2.2326-2.2326V2.23256c0-1.23301 1-2.23256 2.233-2.23256z" />
      <path d="M17.8597 34.9785c0-1.233.9995-2.2326 2.2325-2.2326h23.814c1.233 0 2.2325.9996 2.2325 2.2326s-.9995 2.2325-2.2325 2.2325h-23.814c-1.233 0-2.2325-.9995-2.2325-2.2325z" />
      <path d="M17.8597 46.8837c0-1.233.9995-2.2325 2.2325-2.2325h23.814c1.233 0 2.2325.9995 2.2325 2.2325 0 1.233-.9995 2.2326-2.2325 2.2326h-23.814c-1.233 0-2.2325-.9996-2.2325-2.2326z" />
      <path d="M17.8597 23.0706c0-1.233.9995-2.2325 2.2325-2.2325h5.9535c1.233 0 2.2326.9995 2.2326 2.2325 0 1.233-.9996 2.2326-2.2326 2.2326h-5.9535c-1.233 0-2.2325-.9996-2.2325-2.2326z" />
    </g>
    <defs>
      <clipPath id="FileIconClip">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </IconBox>
);

export default FileIcon;

import { speakersPathnameTranslations, speakersTranslatedRoutes } from 'src/speakers/routes';
import { coursePathnameTranslations, courseTranslatedRoutes } from 'src/course/routes';
import { searchPathnameTranslations, searchTranslatedRoutes } from 'src/search/routes';
import { publicPathnameTranslations, publicTranslatedRoutes } from 'src/public/publicRoutes';
import { elearningPathnameTranslations, elearningTranslatedRoutes } from 'src/elearning/routes';
import { userPathnameTranslations, userTranslatedRoutes } from 'src/user/routes';
import {
  courseBundlePathnameTranslations,
  courseBundleTranslatedRoutes,
} from 'src/courseBundle/routes';

export const translatedPathnames: Record<string, string> = {
  ...coursePathnameTranslations,
  ...elearningPathnameTranslations,
  ...searchPathnameTranslations,
  ...speakersPathnameTranslations,
  ...publicPathnameTranslations,
  ...userPathnameTranslations,
  ...courseBundlePathnameTranslations,
};

export const translatedRoutes = [
  ...courseTranslatedRoutes,
  ...elearningTranslatedRoutes,
  ...searchTranslatedRoutes,
  ...speakersTranslatedRoutes,
  ...publicTranslatedRoutes,
  ...userTranslatedRoutes,
  ...courseBundleTranslatedRoutes,
];
